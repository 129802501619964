((w, d, $) => {
  w.MACHI_LIBRARY = w.MACHI_LIBRARY || {};
  let app = w.MACHI_LIBRARY;

  app.plugin = {};

  app.util = app.util || {};
  app.util.getScrollingElement = function() {
    return d.scrollingElement ||
      (('WebkitAppearance' in d.documentElement.style)? d.body: d.documentElement);
  };

  app.support = app.support || {};
  app.support.customEvent = typeof w.CustomEvent === 'function' || (function() {
    var CustomEvent = function (event, params) {
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: undefined
      };
      var evt = d.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    };

    CustomEvent.prototype = w.Event.prototype;
    w.CustomEvent = CustomEvent;
    return false;
  })();
  app.support.passiveEventListeners = (function() {
    var supported = false;
    try {
      var options = Object.defineProperty({}, 'passive', {
        get: function() {
          supported: true;
        }
      });
      w.addEventListener('test', options, options);
      w.removeEventListener('test', options, options);
    } catch(err) {
      supported = false;
    }

    return supported;
  });
  app.support.scrollBehavior = 'scrollBehavior' in d.documentElement.style;

  String.prototype.toCamelCase = function() {
    let s = this.charAt(0).toLowerCase() + this.slice(1);
    return s.replace(/[_-](.)/g, (match, matches) => {
      return matches.toUpperCase();
    });
  };

  String.prototype.toPascalCase = function() {
    let s = this.toCamelCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  let body = d.body;

  var scrollingElement = app.util.getScrollingElement();

  var $window = $(window),
      $html = $('html'),
      $body = $('body');

  function checkViewportChange(queries) {
    var defaultQueries = {
      mobile: '(max-width: 568px)',
      tablet: '(min-width: 569px) and (max-width: 768px)',
      tabletLarge: '(min-width: 769px) and (max-width: 959px)',
      desktop: '(min-width: 960px)'
    };

    if (!queries || typeof queries !== 'object') {
      queries = {...defaultQueries};
    }

    var devices = {};
    Object.keys(queries).forEach(function(key, index) {
      devices[this[key].replace(/\s+/g, '')] = key;
    }, queries);

    var createEvent = function() {
    };

    var checkViewport = function(mediaQueryList) {
      if (mediaQueryList.matches) {
        var media = mediaQueryList.media.replace(/\s+/g, ''),
            device = devices[media];

        var event = new CustomEvent('changeMatchMedia', {
          detail: {
            device: device
          }
        });
        d.dispatchEvent(event);
        d.documentElement.setAttribute('data-device', device);
      }
    };

    var addEvent = function(mediaQueryList) {
      mediaQueryList.addListener(checkViewport);
    };

    Object.keys(queries).forEach(function(key, index) {
      var mediaQueryList = w.matchMedia(this[key]);
      checkViewport(mediaQueryList);
      addEvent(mediaQueryList);
    }, queries);
  }

  // menu

  function Menu(element, options, index) {
    var defaults = {
      overlay: true,
      menuBodyOutside: false,
      buttonText: false,
      prefix: 'js-menu-',
      className: {
        button: 'button',
        buttonText: 'button-text',
        body: 'body',
        close: 'close',
        overlay: 'overlay',
        wrapper: 'body-wrapper',
        isOpen: 'is-open',
        isClosing: 'is-closing',
      },
      text: {
        open: 'Menu',
        close: 'Close'
      },
      label: {
        open: 'Open Menu',
        close: 'Close Menu'
      }
    };

    element.id = element.id || 'menu' + Date.now() + '-' + index;
    this.element = element;
    this.settings = $.extend({}, defaults, options);
    this._defaults = defaults;

    var self = this,
        s = this.settings;

    'className'.split(' ').forEach(function(key) {
      s['original' + key.charAt(0).toUpperCase() + key.slice(1)] = $.extend({}, s[key]);
      var obj = {};
      Object.keys(s[key]).forEach(function(name) {
        obj[name] = s.prefix + s[key][name];
      });
      s[key] = $.extend({}, obj);
    });

    this.isOpen = false;
    this.init();
  }

  $.extend(Menu.prototype, {
    init: function() {
      var self = this,
          s = this.settings,
          cn = s.className;

      var focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

      this.id = this.element.id;
      this.$element = $(this.element);
      this.$button = this.$element.find('.' + cn.button).eq(0);
      this.$body = this.$element.find('.' + cn.body).eq(0);
      this.$close = this.$element.find('.' + cn.close);

      this.$focusableElements = this.$body.find(focusableElementsString).attr('tabindex', -1);
      this.firstTabStop = this.$focusableElements[0];
      this.lastTabStop = this.$focusableElements[this.$focusableElements.length - 1];

      var $buttonText = s.buttonText && this.$button.find('.' + cn.buttonText).eq(0);
      this.$buttonText = s.buttonText && $buttonText.length? $buttonText: this.$button;

      var buttonId = this.$button.attr('id') || this.element.id + '-' + s.originalClassName.button,
          bodyId = this.$body.attr('id') || this.element.id + '-' + s.originalClassName.body;
      this.$button.attr({
        'id': buttonId,
        'tabindex': 0,
        'role': 'button',
        'aria-controls': bodyId,
        'aria-expanded': false,
        'aria-label': s.label.open,
      });
      this.$body.attr({
        'id': bodyId,
        'aria-labelledby': this.$button.attr('id')
      });

      this.$elementSet = this.$element.add(this.$body);

      this.elementOptions = {overlay: s.overlay, wrapper: s.menuBodyOutside};
      Object.keys(this.elementOptions).forEach(function(name) {
        if (this[name]) {
          var $element = app.plugin.menu['$' + name];
          if (!$element) {
            $element = $('<div>');
            $element.addClass(cn[name]).appendTo($body).on('click.menu', function(e) {
              if (e.target === e.currentTarget) {
                var $this = $(this);
                var $menu = $this.data('$menu');
                if ($menu) {
                  $menu.close();
                } else {
                  $this.removeClass(cn.isOpen);
                }
              }
            });
            app.plugin.menu['$' + name] = $element;
          }
          self['$' + name] = $element;
          self.$elementSet = self.$elementSet.add($element);
        }
      }, this.elementOptions);

      this.$button.on('click.menu keydown.menu keyup.menu', function(e) {
        if (e.type === 'keydown') {
          if (e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault();
            return;
          } else if (!(e.key === 'Enter')) {
            return;
          }
        } else if (e.type === 'keyup') {
          if (!(e.key === ' ' || e.key === 'Spacebar')) {
            return;
          }
        }
        e.preventDefault();
        if (self.isOpen) {
          self.close();
        } else {
          self.open();
        }
        return;
      });

      this.$close.on('click.menu', function(e) {
        self.close();
      });

      this.$body.on('keydown.menu', function(e) {
        if (self.isOpen && e.keyCode === 9) {
          if (e.shiftKey) {
            if (d.activeElement === self.firstTabStop) {
              e.preventDefault();
              self.lastTabStop.focus();
            }
          } else {
            if (d.activeElement === self.lastTabStop) {
              e.preventDefault();
              self.firstTabStop.focus();
            }
          }
        }
      });

      this.$body.on('click', 'a', function(e) {
        e.stopPropagation();
        var hash = this.hash;

        if (!hash || this.pathname !== location.pathname) return;

        var $hash = $(hash);

        if (self.isOpen && hash === '#top' || $hash.length) {
          self.close();
        }
      });

      s.menuBodyOutside && this.$body.appendTo(this.$wrapper);

      d.addEventListener('changeMatchMedia', function(e) {
        var device = e.detail.device;

        if (device === 'mobile') {
          self.close();
          self.element.removeAttribute('aria-hidden');
        } else {
          self.close();
          self.element.setAttribute('aria-hidden', true);
        }
      });

      this.close();

      return this;
    },
    open: function() {
      var s = this.settings,
          cn = s.className;

      this.$elementSet.add($body).addClass(cn.isOpen);

      s.overlay && this.$overlay.data('$menu', this);
      s.menuBodyOutside && this.$wrapper.data('$menu', this);

      this.$button.attr({
        'aria-expanded': true,
        'aria-label': s.label.close
      });
      s.buttonText && this.$buttonText.text(s.text.close);
      this.$focusableElements.attr('tabindex', null);
      this.firstTabStop.focus();
      this.isOpen = true;
      app.plugin.menu.$currentElement = this.$element;

      return this;
    },
    close: function() {
      var self = this,
          s = this.settings,
          cn = s.className;

      this.$elementSet.one('transitionstart', function(e) {
        var $element = $(this);
        if (this !== e.target) return;
        $element.addClass(cn.isClosing).on('transitionend.menuClose', function(e) {
          if (this !== e.target) return;
          $element.removeClass(cn.isClosing).off('transitionend.menuClose');
        });
      });

      s.overlay && this.$overlay.data('$menu', false);
      s.menuBodyOutside && this.$wrapper.data('$menu', false);

      this.$elementSet.add($body).removeClass(cn.isOpen);

      this.$button.attr({
        'aria-expanded': false,
        'aria-label': s.label.open
      });
      s.buttonText && this.$buttonText.text(s.text.open);
      this.$focusableElements.attr('tabindex', -1);
      this.$button[0].focus();
      this.isOpen = false;
      app.plugin.menu.$currentElement = false;

      return this;
    }
  });

  $.fn.menu = function(options) {
    app.plugin.menu = {
      elements: this,
      $overlay: false,
      $currentElement: false
    };

    $(w).on('keydown.menu', function(e) {
      if (app.plugin.menu.$currentElement && (e.key === 'Escape' || e.key === 'Esc')) {
        app.plugin.menu.$currentElement.data('menu').close();
      }
    });

    return this.each(function(index) {
      if (!$.data(this, 'menu')) {
        $.data(this, 'menu', new Menu(this, options, index));
      }
    });
  };

  // smooth scroll

  function SmoothScroll(element, options) {
    var defaults = {
      jqueryAnimationDuration: 500
    };
    this.element = element;
    this.settings = $.extend({}, defaults, options);
    this._defaults = defaults;

    this.init();
  }

  $.extend(SmoothScroll.prototype, {
    init: function() {
      var self = this,
          s = this.settings;

      if (typeof s.target === 'string') {
        $(this.element).on('click.smoothScroll', s.target, function(e) {
          var hash = this.hash;
          if (!self._existsHash(hash)) {
            return;
          }

          e.preventDefault();
          var $target = $(hash);
          var top = (hash === '#top')? 0: $target.offset().top;

          self._scroll(top, hash);
        });
      } else {
        var hash = this.element.hash;
        if (!self._existsHash(hash)) {
          return this;
        }

        this.$target = $(hash);

        $(this.element).on('click.smoothScroll', function(e) {
          e.preventDefault();
          var top = (hash === '#top')? 0: self.$target.offset().top;

          self._scroll(top, hash);
        });
      }

      return this;
    },
    _existsHash: function(hash) {
      return hash && ($(hash).length > 0 || hash === '#top');
    },
    _scroll: function(top, hash) {
      var self = this,
          s = this.settings;

      if (app.support.scrollBehavior) {
        scrollingElement.scrollTo({
          'behavior': 'smooth',
          'top': top
        });
      } else {
        $(scrollingElement).animate({scrollTop: top}, s.jqueryAnimationDuration, 'swing');
      }
      w.history.pushState(null, null, hash);
    }
  });

  $.fn.smoothScroll = function(options) {
    return this.each(function() {
      if (!$.data(this, 'smoothScroll')) {
        $.data(this, 'smoothScroll', new SmoothScroll(this, options));
      }
    });
  };

  let init = () => {
  };

  $(() => {
    init();

    $('.menu').menu({
      overlay: true,
      buttonText: true,
      text: {
        open: 'メニュー',
        close: 'とじる'
      },
      label: {
        open: 'メニューを開く',
        close: 'メニューを閉じる'
      }
    });

    checkViewportChange({
      mobile: '(max-width: 568px)',
      tablet: '(min-width: 569px) and (max-width: 768px)',
      tabletLarge: '(min-width: 769px) and (max-width: 959px)',
      desktop: '(min-width: 960px)'
    });

    let $libraryList = $('.libraryList');
    $libraryList.length && $libraryList.masonry();
  });
})(window, document, jQuery);
